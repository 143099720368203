import breadcrumbComp from "../../common/breadcrumb-comp.vue";
// import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import shippingService from "./shipping-service";
import FilterComp from "../../common/filter-comp.vue";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      soSearchKey: "",
      disableSearch: false,
      poSearchKey: "",
      selectedProcess: "",
      processTypeData: [],
      disableProcessType: false,
      selectedProject: "",
      projectData: [],
      disableProject: true,
      selectedBusiness: "",
      businessData: [],
      disableBusiness: true,
      disableRun: false,
      totalRecords: 0,
      pickingData: [],
      searchFilter: "",
      pickingHeaders: [
        { text: "Project/Business", align: "start", value: "projBusDetails", class: "primary customwhite--text" },
        { text: "Order (linked)", value: "OrderNo", class: "primary customwhite--text" },
        { text: "Customer ID", value: "Customer_ID", class: "primary customwhite--text" },
        { text: "Order Type", value: "Order_Type", class: "primary customwhite--text" },
        { text: "Ordered Date", value: "OrderDate", class: "primary customwhite--text" },
        { text: "Required Date", value: "ReqDate", class: "primary customwhite--text" },
        { text: "Carrier", value: "SLevel", class: "primary customwhite--text" },
        { text: "Status", value: "Status", class: "primary customwhite--text" },
        { text: "Action", value: "Action", class: "primary customwhite--text" },
      ],
      max3Rule: [(v) => /^[a-zA-Z0-9]{3}$/.test(v) || "Only three letters are allowed", (v) => !!v || "Field is required"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProcessData();
  },
  async mounted() {},
  methods: {
    //Reset Function
    async resetFunction() {
      this.soSearchKey = "";
      this.disableSearch = false;
      this.poSearchKey = "";
      this.selectedProcess = "";
      this.processTypeData = [];
      this.disableProcessType = false;
      this.selectedProject = "";
      this.projectData = [];
      this.disableProject = true;
      this.selectedBusiness = "";
      this.businessData = [];
      this.disableBusiness = true;
      this.disableRun = false;
      this.totalRecords = 0;
      this.pickingData = [];
      this.searchFilter = "";
      this.getProcessData();
    },
    //Process Data
    async getProcessData() {
      let obj = {
        UserID: parseInt(this.userId),
        ddl_pass: "1",
        process_list: "",
        projkey_list: "",
        bukey_list: "",
      };
      let showMessage = false;
      let data = await shippingService.getPickingQueueDrop("post", obj, showMessage);
      if (data.message !== "NA") {
        this.processTypeData = data;
      }
    },
    //Get Project Data
    async getProjectData() {
      let processList = [];
      processList.push(this.selectedProcess);
      let obj = {
        UserID: parseInt(this.userId),
        ddl_pass: "2",
        process_list: JSON.stringify(processList),
        projkey_list: "",
        bukey_list: "",
      };
      let showMessage = false;
      let data = await shippingService.getPickingQueueDrop("post", obj, showMessage);
      if (data.message !== "NA") {
        this.projectData = data;
        this.disableProject = false;
        this.disableProcessType = true;
      }
    },
    //Get Business Data
    async getBusinessData() {
      let processList = [];
      processList.push(this.selectedProcess);
      let projectList = [];
      projectList.push(this.selectedProject);
      let obj = {
        UserID: parseInt(this.userId),
        ddl_pass: "3",
        process_list: JSON.stringify(processList),
        projkey_list: JSON.stringify(projectList),
        bukey_list: "",
      };
      let showMessage = false;
      let data = await shippingService.getPickingQueueDrop("post", obj, showMessage);
      if (data.message !== "NA") {
        this.businessData = data;
        this.disableBusiness = false;
        this.disableProject = true;
      }
    },
    //Run button click event
    async runReport() {
      let processList = [];
      processList.push(this.selectedProcess);
      let projectList = [];
      projectList.push(this.selectedProject);
      let businessList = [];
      businessList.push(this.selectedBusiness);
      let jsonObject = JSON.stringify({
        process_list: processList,
        projkey_list: projectList,
        bukey_list: businessList,
      });
      let obj = {
        UserID: parseInt(this.userId),
        json: jsonObject,
      };
      let showMessage = false;
      let data = await shippingService.pickingQueueSearch("post", obj, showMessage);
      if (data.message !== "NA") {
        this.pickingData = data;
        this.totalRecords = data.length;
      } else {
        this.pickingData = [];
        this.totalRecords = 0;
        let Alert = {
          type: "success",
          isShow: true,
          message: "Receiving/Inventory Printer is successfully configured.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    FilterComp,
  },
};
