import { render, staticRenderFns } from "./fulfillment-picking-queue.vue?vue&type=template&id=9b3f75a0&"
import script from "./js/fulfillment-picking-queue.js?vue&type=script&lang=js&"
export * from "./js/fulfillment-picking-queue.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports